@import "../../../assets/colors";

#redirect {
  height: 100vh;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bondiBlue;

  .epa-background {
    width: 65%;
    position: fixed;
    top: -15%;
    right: -15%;
    z-index: 1;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 38px;
    top: 38px;

    .epa-logo {
      width: auto;
      height: 40px;
    }

    .label {
      margin: 0 0 0 24px;
      color: $white;
      font-size: 34px;
      line-height: 32px;
    }
  }

  .user-infos {
    position: absolute;
    left: 36px;
    top: 100px;
    color: $white;
    font-weight: 100;

    .user-role {
      position: relative;
      top: 5px;
    }
  }

  .logout {
    z-index: 2;
    cursor: pointer;
    color: $white;
    position: absolute;
    right: 38px;
    top: 38px;
    font-size: 36px;
    text-shadow: 0px 2px 3px rgba(0,0,0,0.2);
  }

  .redirection-wrapper {
    width: 100%;
    z-index: 2;
    position: relative;
    max-width: 570px;
    min-width: 280px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    background-color: $white;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12), 0 12px 12px 0 rgba(0, 0, 0, 0.24);

    .title {
      margin: 60px 80px 36px 80px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: $codGray;
    }

    > .separator {
      width: 100%;
      margin: 0;
      border-top: dotted 1px $alto;
      border-bottom: none;
      border-left: none;
      border-right: none;
    }

    .message-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 80px 48px 80px;
      box-sizing: border-box;

      p {
        text-align: center;
      }
    }

    .action-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 20px 80px 48px 80px;
      box-sizing: border-box;

      .button {
        min-width: 150px;
        max-width: calc(50% - 4*16px);
        flex: 1;
        margin: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #redirect {
    overflow-y: scroll;
    justify-content: normal;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      top: 0;
      left: 0;
      margin: 24px;

      .epa-logo {
        width: auto;
      }

      .label {
        margin: 0 0 0 12px;
        font-size: 28px;
      }
    }

    .user-infos {
      position: relative;
      top: -16px;
      left: 0px;
      text-align: center;
      margin-bottom: 12px;

      >p {
        font-size: 14px;
      }
    }

    .logout {
      right: 10px;
      top: 10px;
      font-size: 32px;
    }

    .redirection-wrapper {
      .title{
        margin: 60px 40px 36px 40px;
        text-align: center;
      }

      .message-wrapper {
        padding: 20px 40px 20px 40px;
      }

      .action-wrapper {
        padding: 20px 40px 48px 40px;

        .button {
          max-width: none;
        }
      }
    }
  }
}


