@import "../../../assets/colors";

.drop-down {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &.invalid {
    .selector-container .box {
      border-bottom: 2px solid $scarlet;
    }
  }

  .label {
    color: $lynch;
    font-size: 12px;
  }

  .selector-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .box {
      height: 50px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px;
      box-sizing: border-box;
      border-bottom: 2px solid #FFFFFF;
      background-color: $athensGray;
      cursor: pointer;
      justify-content: space-between;

      .current {
        color: $black;
        font-size: 16px;
        line-height: 20px;
      }

      .arrow {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $bombay;
        transition: transform .2s ease-in;

        &.up {
          transform: rotate(180deg);
        }
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    .list {
      max-height: calc(50vh - 200px);
      margin-bottom: 15px;
      position: absolute;
      right: 0;
      left: 0;
      top: 50px;
      opacity: 0;
      background-color: $athensGray;
      transition: all .2s ease-in;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      overflow-y: scroll;
      z-index: -1;

      .row {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 8px 12px;
        transition: background-color .2s ease-in;

        p {
          color: black;
          font-size: 16px;
          line-height: 0;
        }

        &:hover {
          background-color: $bombay;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      &.display {
        opacity: 1;
        z-index: 10;
      }
    }
  }
}
