#registerForm {
  padding-left: 80px;
  padding-right: 80px;

  .civility-container {
    display: flex;
    flex-direction: row;

    .drop-down .selector-container .list {
      overflow-y: auto;
    }

    .civility {
      flex: 2;
    }

    .firstName {
      flex: 3;
    }

    .lastName {
      flex: 4;
    }

    > div {
      margin-left: 8px;
      margin-right: 8px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .email {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .region {
    margin-bottom: 24px;
  }

  .cgu {
    margin: 15px 0;
    font-size: 12px;
    color: #688197;
    font-family: "Ubuntu", sans-serif;
  }
}

@media screen and (max-width: 640px) {
  #registerForm {
    padding-left: 40px;
    padding-right: 40px;

    .civility-container {
      flex-direction: column;

      > div {
        margin-top: 12px;
        margin-left: 0;
        margin-right: 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
