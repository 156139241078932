// (Colors are named with http://chir.ag/projects/name-that-color/#71B239)
$alto: #D1D1D1;
$athensGray: #F8F8F9;
$black: #000000;
$bombay: #B1B2BA;
$bondiBlue: #00A0AF;
$cerulean: #0093E0;
$codGray: #1E1E1E;
$lynch: #688197;
$moutainMeadow: #1DB46C;
$rollingStone: #748285;
$scarlet: #EA1806;
$white: #FFFFFF;
