@import "../../../assets/colors";

.icon-input {
  display: flex;
  flex-direction: column;

  > span {
    color: $lynch;
    font-size: 12px;
  }

  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    box-sizing: border-box;
    border-bottom: 2px solid $white;
    background-color: $athensGray;

    &.invalid {
      border-bottom: 2px solid $scarlet;
    }

    input {
      height: 48px;
      width: 100%;
      font-size: 16px;
      box-sizing: border-box;
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    .material-icons {
      cursor: default;
      margin-left: 16px;
      color: $rollingStone;

      &.action {
        cursor: pointer;
      }
    }
  }

  > .error {
    color: $scarlet;
    font-size: 12px;
  }
}

